import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import CardSelectors from 'Stores/Card/Selectors'
import CardActions from 'Stores/Card/Actions'
import RequestState from 'Enums/RequestState'
import Styles from './ChargingTokenTransactionList.module.css'
import { Waypoint } from 'react-waypoint'
import {
  TransactionListItem,
  TransactionListItemSkeleton,
  CustomScrollbar,
} from 'Components'
import { EmptyLogs } from 'Components'
import { NoLogsFilter } from 'Components'
import InfinityEnums from 'Enums/InfinityEnums'
import { Virtuoso } from 'react-virtuoso'
const { ConnectorPowerLevel } = InfinityEnums

const ChargingTokenTransactionList = ({
  fetchChargingTokenTransactions,
  fetchChargingTokenTransactionsRequestState,
  chargingTokenTransactions,
  paginationOffset,
  cardTransactionsFilter,
  chargingTokens,
  selectedChargingTokenIndex,
  fetchChargingTokensRequestState,
  ascending,
  selectedCard,
  fetchCardRequestState,
}) => {
  const [empty, setEmpty] = useState(true)

  const Footer = () => {
    return paginationOffset &&
      chargingTokenTransactions &&
      chargingTokenTransactions.length > 0 ? (
      <div className={Styles.WaypointContainer}>
        <Waypoint onEnter={loadMoreData} />
        <TransactionListItemSkeleton />
        <TransactionListItemSkeleton />
      </div>
    ) : null
  }

  const loadMoreData = () => {
    if (
      fetchChargingTokenTransactionsRequestState === RequestState.LOADING_MORE
    )
      return
    fetchChargingTokenTransactions(
      cardTransactionsFilter,
      paginationOffset,
      chargingTokens[selectedChargingTokenIndex]._id,
      ascending,
    )
  }

  const handleMaxPower = (maxPower) => {
    if (maxPower <= ConnectorPowerLevel.LEVEL1)
      return InfinityEnums.ChargingSpeedLevels.LEVEL1
    else if (maxPower <= ConnectorPowerLevel.LEVEL2)
      return InfinityEnums.ChargingSpeedLevels.LEVEL2
    else return InfinityEnums.ChargingSpeedLevels.LEVEL3
  }

  const checkFilterEmpty = (newFilter) => {
    const emptyFlag = !newFilter.some((filter) => filter.value.length !== 0)
    if (emptyFlag) setEmpty(true)
    else setEmpty(false)
  }

  useEffect(() => {
    checkFilterEmpty(cardTransactionsFilter)
  }, [cardTransactionsFilter])

  useEffect(() => {
    if (selectedCard)
      fetchChargingTokenTransactions(
        cardTransactionsFilter,
        paginationOffset,
        selectedCard._id,
        ascending,
      )
  }, [selectedCard])

  return (
    <div className={Styles.container}>
      {(fetchChargingTokenTransactionsRequestState === RequestState.LOADING ||
        fetchCardRequestState === RequestState.LOADING ||
        fetchChargingTokensRequestState === RequestState.LOADING) &&
      paginationOffset === 0 ? (
        [...Array(15)].map((e, index) => (
          <TransactionListItemSkeleton key={index} />
        ))
      ) : chargingTokenTransactions.length === 0 ? (
        empty ? (
          <EmptyLogs />
        ) : (
          <NoLogsFilter />
        )
      ) : (
        <Virtuoso
          data={chargingTokenTransactions}
          endReached={
            paginationOffset &&
            chargingTokenTransactions &&
            chargingTokenTransactions.length !== 0
              ? loadMoreData
              : () => {}
          }
          increaseViewportBy={480}
          itemContent={(index, chargingTokenTransaction) => {
            return (
              <div key={index}>
                <TransactionListItem
                  stationName={chargingTokenTransaction.station[0].name}
                  chargePointName={chargingTokenTransaction.chargePoint[0].name}
                  chargePointId={chargingTokenTransaction.chargePoint[0]._id}
                  stationId={chargingTokenTransaction.station[0]._id}
                  energyConsumption={chargingTokenTransaction.energyConsumption}
                  amount={chargingTokenTransaction.amount}
                  processedAt={chargingTokenTransaction.processedAt}
                  endedAt={chargingTokenTransaction.endedAt}
                  startedAt={chargingTokenTransaction.startedAt}
                  connectorUid={chargingTokenTransaction.connectorUid}
                  connectorType={
                    chargingTokenTransaction.chargePoint[0].connectors.find(
                      (connector) =>
                        connector.uid === chargingTokenTransaction.connectorUid,
                    )?.type
                  }
                  tariff={chargingTokenTransaction?.subscription?.tariff}
                  subscription={chargingTokenTransaction?.subscription?.name}
                  chargingLevel={handleMaxPower(
                    chargingTokenTransaction.chargePoint[0].connectors.find(
                      (connector) =>
                        connector.uid === chargingTokenTransaction.connectorUid,
                    )?.maxPower,
                  )}
                />
              </div>
            )
          }}
          components={{ Footer: Footer, Scroller: CustomScrollbar }}
        />
      )}
    </div>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    fetchChargingTokenTransactions: (
      filter,
      offset,
      chargingTokenId,
      ascending,
    ) =>
      dispatch(
        CardActions.fetchCardTransactions(
          filter,
          offset,
          chargingTokenId,
          ascending,
        ),
      ),
  }
}

const mapStateToProps = (state) => ({
  chargingTokenTransactions: CardSelectors.getCardTransactions(state),
  fetchChargingTokenTransactionsRequestState:
    CardSelectors.getFetchCardTransactionsRequestState(state),
  cardTransactionsFilter: CardSelectors.getCardTransactionsFilter(state),
  paginationOffset: CardSelectors.getCardTransactionsPaginationOffset(state),
  chargingTokens: CardSelectors.getCards(state),
  selectedChargingTokenIndex: CardSelectors.getSelectedIndex(state),
  fetchChargingTokensRequestState:
    CardSelectors.getFetchCardListRequestState(state),
  selectedCard: CardSelectors.getSelectedCard(state),
  fetchCardRequestState: CardSelectors.getFetchCardRequestState(state),
})

ChargingTokenTransactionList.propTypes = {
  fetchChargingTokenTransactions: PropTypes.func,
  chargingTokenTransactions: PropTypes.arrayOf(PropTypes.object),
  fetchChargingTokenTransactionsRequestState: PropTypes.number,
  paginationOffset: PropTypes.number,
  fetchUserTransactions: PropTypes.func,
  cardTransactionsFilter: PropTypes.string,
  chargingTokens: PropTypes.array,
  selectedChargingTokenIndex: PropTypes.number,
  fetchChargingTokensRequestState: PropTypes.number,
  ascending: PropTypes.bool,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChargingTokenTransactionList)
